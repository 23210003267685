// Modules
import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";

// Utils
import { formatDate, convertToKebabCase } from "../utils/helpers";

// Components
import SEO from "../components/SEO";
import Button from "../components/Button";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SearchBar from "../components/SearchBar";
import HeroSection from "../components/HeroSection";
import stockperksImg from "../images/stockperks.png";
import Card, {
  CardBody,
  CardText,
  CardImage,
  CardTitle,
  CardGridFlex,
  CardSubTitle,
  CardImagePlaceholder
} from "../components/Card";
import { H1 } from "../components/TextHeadings";
import { columnSize, windowSize } from "../utils/responsive";

const BlogPage = ({ data }) => {
  const [articles, setArticles] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [articlesLimit, setArticlesLimit] = useState(6);

  // Use effect to set more articles regarding the actual limit
  useEffect(() => {
    const articles = getArticlesFilteredByText();
    setArticles(articles.slice(0, articlesLimit));
  }, [articlesLimit, searchText]);

  const getArticlesFilteredByText = () => {
    if (!searchText.trim()) return data.allStrapiArticle.edges;

    return data.allStrapiArticle.edges.filter(({ node }) => {
      return node.title.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  const handleSearch = searchText => {
    setArticlesLimit(6);
    setSearchText(searchText);
  };

  const showMoreArticles = () => {
    setArticlesLimit(articlesLimit + 6);
  };

  return (
    <Layout>
      <SEO
        title="Stockperks Blog"
        url={"/blog/"}
        description={
          "Read our recent posts about investment and our innovative product."
        }
        image={stockperksImg}
      />
      <Section>
        <div style={styles.BlogHeader}>
          <H1>News & Blog</H1>
        </div>
      </Section>
        <Section isTextCentered={true} stripe={true}>
          <div style={{ width: "calc(100% - 50px)"}}>
          <SearchBar
            disabled={false}
            placeholder="Search articles by title"
            onChange={handleSearch}
            value={searchText}
          />
          </div>
          {articles.length > 0 && (
          <CardGridFlex>
            {articles.map(({ node }) => {
              return (
                <Link
                  to={`${node.strapiId}/${convertToKebabCase(node.title)}`}
                  key={node.strapiId}
                >
                  <Card isHoverable={true}>
                    {node.image ? (
                      <CardImage src={node.image?.publicURL} alt={node.title} />
                    ) : (
                      <CardImagePlaceholder />
                    )}
                    <CardBody>
                      <CardSubTitle>{node.category.name}</CardSubTitle>
                      <CardTitle>{node.title}</CardTitle>
                      <CardText>{formatDate(node.published_at)}</CardText>
                    </CardBody>
                  </Card>
                </Link>
              );
            })}
          </CardGridFlex>
          )}
        </Section>

      <Section stripe={true} isTextCentered={true} style={{paddingBottom: 124}}>
        {articles.length < getArticlesFilteredByText().length ? (
          <Button type="secondary" onClick={showMoreArticles}>
            Show more
          </Button>
        ) : searchText.length > 0 && articles.length === 0 ? (
          <p>No articles match your search</p>
        ) : (
          <p>All articles loaded.</p>
        )}
      </Section>
    </Layout>
  );
};

const styles = {
  BlogHeader: {
    display: "flex",
    flexDirection:"column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: 124,
    marginBottom: 124,
    width: "calc(100% - 50px)",
    maxWidth:  `${windowSize.large}px`, textAlign: "left"
  }
}

export default BlogPage;

export const blogPostsQuery = graphql`
  query BlogPostsQuery {
    allStrapiArticle(sort: { fields: [published_at], order: DESC }) {
      edges {
        node {
          strapiId
          title
          category {
            name
          }
          image {
            publicURL
          }
          published_at
        }
      }
    }
  }
`;
