import React from "react";
import styled from "styled-components";
import colors from "../utils/colors";
import PropTypes from "prop-types";
import { columnSize } from "../utils/responsive";
import searchIcon from "../images/icons/search.svg";

const SearchBar = ({ disabled, onChange, placeholder, value }) => {
  const handleChange = event => {
    onChange(event.target.value);
  };

  return (
    <SearchBarContainer>
      <SearchBarInput
        disabled={disabled}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
      />
      <SearchBarIcon src={searchIcon} alt="Search icon" />
    </SearchBarContainer>
  );
};

SearchBar.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired
};

export default SearchBar;

const SearchBarContainer = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  padding: 7px 13px;
  width: 100%;
  margin: 0 auto;
  max-width: ${columnSize.large + 25}px;
`;

const SearchBarInput = styled.input`
  appearance: none;
  background: none;
  border: none;
  font-family: Avenir, helvetica, sans-serif;
  font-size: 16px;
  width: 100%;
  &:focus {
    outline: 0;
  }
  ::placeholder {
    color: ${colors.gray};
  }
`;

const SearchBarIcon = styled.img`
  width: 15px;
`;
