import styled from "styled-components";
import colors from "../utils/colors";
import PropTypes from "prop-types";
import { windowSize } from "../utils/responsive";

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 30px;
  row-gap: 30px;
  max-width: ${windowSize.large}px;
  @media (max-width: ${windowSize.medium}px) {
    width: 100%;
    margin: 25px;
  }
`;

export const CardGridFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: ${windowSize.large + 50}px ;
  padding: 25px;
  > * {
    width: 30%;
    margin: 10px;
    @media (max-width: 996px) {
      width: 40%;
    }
    @media (max-width: ${windowSize.medium}px) {
      padding: 25px;
      width: 100%;
      margin: 10px 0;
    }
  }
`;

const Card = styled.div`
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  align-items: center;
  ${props => props.maxWidth && `max-width: ${props.maxWidth}px`}
`;

Card.propTypes = {
  maxWidth: PropTypes.number
};

export default Card;

export const CardLeftIcon = styled.img`
  cursor: pointer;
  left: 20px;
  position: absolute;
  top: 20px;
`;

export const CardImage = styled.img`
  align-items: center;
  background: ${colors.lightGray};
  display: flex;
  height: 150px;
  justify-content: center;
  object-fit: cover;
  width: 100%;
  @media (max-width: ${windowSize.medium}px) {
    width: 100%;
  }
`;

export const CardImagePlaceholder = styled.div`
  background: ${colors.darkGray};
  height: 150px;
  width: 100%;
`;

export const CardBody = styled.div`
  padding: 20px;
`;

export const CardProfilePhoto = styled.img`
  background: ${colors.gray};
  border: 1px solid ${colors.lightGray};
  border-radius: 50%;
  display: inline-block;
  height: 100px;
  margin-top: 10px;
  object-fit: cover;
  overflow: hidden;
  width: 100px;
`;

export const CardTitle = styled.h3`
  margin: 10px 0;
`;

export const CardSubTitle = styled.p`
  margin: 5px 0;
`;

export const CardText = styled.p`
  color: ${colors.black};
  font-size: 14px;
  font-weight: 400;
`;
